import { Button } from "primereact/button";
import { StyleClass } from 'primereact/styleclass';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ENUM_ICONS } from "../Components/Icons";
import client, { getData } from "../feathers";
import { useBreadcrumbs, useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useUserSwitchMode, useUserSwitchModeUpdate } from "../Persistence/CurrentUserContext";
import { updateLastAction } from '../Persistence/LoginContext';
import logger from '../Utils/logger';
import { Breadcrumbs } from './Breadcrumbs';
import { ENUM_ROUTES, ROUTES } from "./Routes";
import { Sidebar } from './SIdebar';
import { InputSwitch } from 'primereact/inputswitch';
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useTranslation } from "react-i18next";
import { useDataViewMounted } from "../Persistence/DataViewFilterContext";
import { Dialog } from "primereact/dialog";
import { useSocketError } from "../Persistence/SocketErrorContext";

export const NavigationPage = () => {
	const { t } = useTranslation();
	const socketError = useSocketError();
	const currentUser = useCurrentUser();
	const breadcrumbs = useBreadcrumbs();
	const userSwitchMode = useUserSwitchMode();
	const setUserSwitchUpdate = useUserSwitchModeUpdate();
	const configurationsList = useConfigurationsList();
	const dataViewMounted = useDataViewMounted();
	const btnRef6 = useRef(null);
	const location = useLocation();
	const [showSidebarLabel, setShowSidebarLabel] = useState(currentUser?.displaySidebar === 2);
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [mounted, setMounted] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [eaamoduleFBiB, setEaamoduleFBiB] = useState(false);
	const [displayConnectionError, setDisplayConnectionError] = useState(false);

	useEffect(() => {
		setDisplayConnectionError(socketError !== null)
	}, [socketError])

	useEffect(() => {
		const configurationComplete = configurationsList && configurationsList.StatusActiveList
		setEaamoduleFBiB(configurationsList.eaaModules.FBIB === '1')
		setMounted(configurationComplete)
	}, [configurationsList])

	useEffect(() => {
		setShowSidebarLabel(currentUser?.displaySidebar === 2)
	}, [currentUser])

	useEffect(() => {
		var handlekeyDown = window.addEventListener('keydown', (event) => { updateLastAction(); });
		var handleClick = window.addEventListener('click', (event) => { updateLastAction(); });

		//set breadcrumbs
		let route = null;
		const paths = location.pathname.split('/');
		if (paths.length > 1) {
			for (let entry in ROUTES) {
				if (ROUTES[entry].route === paths[1]) {
					const items = [{ label: ROUTES[entry].label, route: ROUTES[entry].route }];

					if (paths.length > 2 && /^\d+$/.test(paths[2])) {
						if (ROUTES[entry].service) {
							client.service(ENUM_SERVICES.UTILS).create({ method: 'breadcrumb', service: ROUTES[entry].service, serviceId: paths[2] }).then((result) => {
								if (result) {
									let label = result.breadcrumb + ''
									label = label.length > 50 ? label.substring(0, 49) + '...' : label;
									items.push({ label, route: ROUTES[entry].route + '/' + paths[2] })
								}
								updateBreadcrumbs({ home: { icon: ROUTES[entry].icon }, items })
							})
						}
					} else {
						updateBreadcrumbs({ home: { icon: ROUTES[entry].icon }, items })
					}
					break;
				}
			}
		}

		return () => { logger.debug('clear Inteval timeIntervalExpire & eventListener'); window.removeEventListener('click', handleClick); window.removeEventListener('keydown', handlekeyDown); }
	}, []);

	const onSidebarClick = (route, icon, label) => {
		updateBreadcrumbs({ home: { icon }, items: [{ label, route }] })
	}
	const switchToFBiB = () => {
		setToggle(false)
		onSidebarClick(ENUM_ROUTES.FBIB_DASHBOARD, ENUM_ICONS.DASHBOARD, 'Dashboard')
	}

	return (
		!mounted ? <></> : <>
			<Dialog
				style={{ maxWidth: '600px' }}
				closable={false}
				modal={true}
				visible={displayConnectionError}
				headerClassName='hidden'
				header={false}
			>
				<div className='ml-5'><h2><b>Der Server ist derzeit nicht erreichbar</b></h2></div>
				<div className='flex flex-column gap-2 m-5'>

					<div className='flex'>Aktuell besteht keine Verbindung zu unserem Server.</div>
					<div className=''>Um Datenverlust zu vermeiden,
						<ul style={{ margin: '5px' }}>
							<li>laden Sie die Seite bitte <u><b>nicht</b></u> neu und </li>
							<li>schließen Sie den Browser <u><b>nicht</b></u>,</li>
						</ul>
						solange dieses Fenster sichtbar ist.
					</div>
					<div className='flex'>
						<div>Sobald die Verbindung wiederhergestellt ist, schließt sich dieses Fenster <b>automatisch</b> und Sie können wie gewohnt weiterarbeiten.</div>
					</div>
					<div className='flex'>Dauert die Störung länger als einige Minuten, überprüfen Sie bitte Ihre Internetverbindung, indem Sie eine andere Webseite in einem neuen Tab aufrufen.</div>
					<div className='flex'><div>Funktioniert diese einwandfrei, kontaktieren Sie uns gerne <br />per E-Mail unter: <a href='mailto:Support@bos-connect.de' >Support@bos-connect.de</a>.</div></div>
				</div>
			</Dialog>
			<div className="min-h-screen flex relative lg:static overflow-hidden">
				<div id="app-sidebar" className="h-screen surface-section hidden lg:block flex-shrink-0  left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-auto select-none">
					<div className={'hidden lg:block h-full'}
						onMouseEnter={() => { if (currentUser.displaySidebar === 1) { setShowSidebarLabel(true) } }}
						onMouseLeave={() => { if (currentUser.displaySidebar === 1) { setShowSidebarLabel(false) } }}>
						<Sidebar onSidebarClick={onSidebarClick} showSidebarLabel={showSidebarLabel} />
					</div>
					<div className={'block lg:hidden h-full'} >
						<Sidebar onSidebarClick={onSidebarClick} showSidebarLabel={true} />
					</div>
				</div>
				<div className="min-h-screen flex flex-column relative flex-auto">
					<div className={"flex align-items-center px-3 relative lg:static border-bottom-1 surface-border " + (userSwitchMode ? 'bg-red-100' : 'surface-section ')} style={{ height: '60px' }}>
						<div className="flex ">
							<StyleClass nodeRef={btnRef6} selector="#app-sidebar" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
								<a ref={btnRef6} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
									<i className="fa fa-bars text-4xl"></i>
								</a>
							</StyleClass>
						</div>
						<div className='flex flex-grow-1'>
							<div>
								<Breadcrumbs />
							</div>
						</div>
						<div>
							<div className="flex flex-row justify-content-center gap-3">
								<div className='flex'>
									<div className={eaamoduleFBiB ? 'flex px-2 align-self-center' : 'hidden'} style={{ fontSize: '8pt' }}>
										EAA
									</div>
									<div className={eaamoduleFBiB || true ? 'flex flex-grow-1 justify-content-end align-self-center sidebar' : 'hidden'}>
										<InputSwitch className='' checked={toggle} onChange={switchToFBiB} role='checkbox' />
									</div>
									<div className={eaamoduleFBiB ? 'flex px-2 align-self-center' : 'hidden'} style={{ fontSize: '8pt' }}>
										FBiB
									</div>
								</div>

								<Button onClick={() => { setUserSwitchUpdate(null) }} icon={ENUM_ICONS.CASE_DELETE} className={!userSwitchMode ? 'hidden' : "p-button-rounded p-button-outlined color-lessblack mx-4"} />
								<Button
									onClick={() => { onSidebarClick(ENUM_ROUTES.USERPROFILE, ENUM_ICONS.USER, 'Nutzer*innenprofil') }}
									icon={ENUM_ICONS.USER}
									aria-label={t('navigation-page.userprofile.aria-label')}
									className={"p-button-rounded p-button-outlined " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === ENUM_ICONS.USER ? 'color-text-blue' : 'color-lessblack')} />
							</div>
						</div>
					</div>
					<div className="flex justify-content-center  m-0 flex-auto surface-ground w-full" >
						<div className="pl-2 m-0 surface-border overflow-auto w-full" style={{ maxWidth: 'calc(100vw - 60px)', height: 'calc(100vh - 60px)' }}>
							{!dataViewMounted ? '' : <Outlet />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}