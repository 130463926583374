import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { useAssociatedOrganisationsSelectionList } from "../Persistence/AssociatedOrganisationsContext";

export const AssociatedOrganizationChooseDialog = ({ formik, associatedOrganizationId, className }) => {

	const associatedOrganizationsSelectionList = useAssociatedOrganisationsSelectionList();

	return <>
		<DropdownChangesChecked
			id={'id'}
			label={'Netzwerkkorganisation'}
			formik={formik}
			key={'dropdown'}
			panelClassName='p-2  dropdown-associated-contact-select'
			type='text'
			filter={true}
			filterBy="name"
			optionLabel='name'
			itemLabel='name'
			optionValue='id'
			options={associatedOrganizationsSelectionList.filter((e) => associatedOrganizationId !== e.id)}
			placeholder='Netzwerkorganisation wählen'
			className={(className ? className : 'w-full')}
			showClear={true} emptyMessage='Keine Auswahl vorhanden' />
	</>
}