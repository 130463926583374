import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useEffect, useState } from 'react';
import { ENUM_ICONS } from "../Components/Icons";
import { ToastError } from "../Utils/ToastError";
import { Card } from "primereact/card";

import { useConfigurationsList, useFBiBMode } from "../Persistence/ConfigurationsContext";
import { useFormik } from "formik";
import { getEmptyAssociatedOrganization, validateAssociatedOrganization } from "./AssociatedOrganizationForm";
import { getData } from "../feathers";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { getAssociatedOrganization } from "./AsociatedOrganizationsManager";
import { useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { listStatus } from "../Components/Layouts/BasicSheetSubtitle";
import { Tag } from "primereact/tag";

const SERVICENAME = ENUM_SERVICES.ASSOCIATEDORGANIZATIONS;
const CLASSNAME = 'AssociatedOrganizationSheet'
export const AssociatedOrganizationSheet = ({ readonly, associatedOrganizationId }) => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const fbibMode = useFBiBMode();
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const [associatedOrganization, setAssociatedOrganization] = useState();
	const [route, setRoute] = useState();

	useEffect(() => {
		if (associatedOrganizationId) {
			getAssociatedOrganization(associatedOrganizationId, setAssociatedOrganization, isReader, null, null, null)
		}
		setRoute(fbibMode ? ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS : ENUM_ROUTES.ASSOCIATEDORGANIZATIONS)
	}, [associatedOrganizationId])


	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Netzwerkorganisation</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>{!associatedOrganization ? 'kein Organisation gesetzt' : associatedOrganization.name} </div>
				<div className='flex gap-2'>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { addBreadcrumb(``, `${route}/` + associatedOrganization.id) }}><i className={ENUM_ICONS.EYE} /></div>
				</div>

			</div>
		</div>
	}

	/** formik EaaCase */
	const formik = useFormik({
		initialValues: getEmptyAssociatedOrganization(),
		validate: validateAssociatedOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderAssociatedOrganization = () => {
		const status = listStatus.find(entry => entry.alias === associatedOrganization.active)
		return !associatedOrganization ? '' : <>
			<Card className={'companysheet card-sheet border-none mb-3'} >
				<div className={'grid'}>
					<div className="col-fixed">Telefon</div>
					<div className="col">{associatedOrganization.phone}</div>
				</div>
				<div className={'grid'}>
					<div className="col-fixed">Fax</div>
					<div className="col">{associatedOrganization.fax}</div>
				</div>
				<div className={'grid'}>
					<div className="col-fixed">Mobil</div>
					<div className="col">{associatedOrganization.mobile}</div>
				</div>
				<div className={'grid'}>
					<div className="col-fixed">E-Mail</div>
					<div className="col">{associatedOrganization.email}</div>
				</div>
				<div className={'grid'}>
					<div className="col-fixed">Region</div>
					<div className="col">{!associatedOrganization || !associatedOrganization.region ? '' : associatedOrganization.region.name}</div>
				</div>
				<div className={'grid'}>
					<div className="col-fixed">Status</div>
					<div className="col"><Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} /></div>
				</div>
			</Card>
		</>
	}

	return (
		<>
			{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' || !associatedOrganization ? '' : <Card className={"associatedcontactsheet card-sheet"} header={renderHeader}>
				{renderAssociatedOrganization()}
			</Card>}
		</>
	)

}