import { useState, useEffect } from 'react'
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { useCurrentUser, useUserSwitchMode } from "../../Persistence/CurrentUserContext"
import { NavigationEntry } from "../../Navigation/NavigationEntry"
import { ENUM_ROUTES, ROUTES } from "../../Navigation/Routes"
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { logout } from "../../feathers";
import { ErrorBoundary } from "react-error-boundary";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../../Components/Icons";
import { InputSwitch } from 'primereact/inputswitch';
import { Badge } from 'primereact/badge';
import { useRemindersList } from '../../Persistence/RemindersContext';

export const FBiBSidebar = ({ onSidebarClick, showSidebarLabel }) => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const remindersList = useRemindersList();
	const [toggle, setToggle] = useState(true)
	const [badgeReminders, setBadgeReminders] = useState();

	useEffect(() => {
		const unreadReminders = !remindersList ? [] : remindersList.filter(e => e.reminded === -1)
		if (unreadReminders.length > 0) {
			setBadgeReminders(<Badge value={'' + unreadReminders.length} severity='danger' />);
		} else {
			setBadgeReminders(null);
		}
	}, [remindersList])

	const renderMenulink = () => {
		return !configurationsList.eaaModules.FBIBMENULINK ? '' :
			configurationsList.eaaModules.FBIBMENULINK.map((link) => {
				return <NavigationEntry key={'link-' + link.key} icon={link.icon} label={link.title} route={ENUM_ROUTES.DASHBOARD} onSidebarClick={() => { window.open(link.url, '_blank') }} showLabel={showSidebarLabel} />
			})
	}

	function ErrorFallback({ error, resetErrorBoundary }) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		)
	}

	const switchToEAA = () => {
		setToggle(false)
		onSidebarClick(ENUM_ROUTES.DASHBOARD, ENUM_ICONS.DASHBOARD, 'Dashboard')
	}

	const onLogout = () => {
		logout(currentUser)
		if (window.location.pathname !== '/') {
			window.location.replace(window.location.protocol + '//' + window.location.host);
		}
	}

	return (
		<div className="flex flex-column h-full sidebar" >
			<div className="flex pl-2 pt-3 w-full  overflow-hidden" style={{ height: '50px' }}>
				<div className='flex align-self-center'>
					<img src={window.location.origin + "/icons/fbib_logo.png"} style={{ height: '30px' }} alt='FBiB-Logo' />
				</div>
				<div className={showSidebarLabel && false ? 'flex flex-grow-1 justify-content-end align-self-center' : 'hidden'} style={{ fontSize: '8pt' }}>
					Wechsel zu EAA
				</div>
				<div className={showSidebarLabel && false ? 'flex align-self-center px-2 ' : 'hidden'}>
					<InputSwitch className='' checked={toggle} onChange={switchToEAA} />
				</div>

			</div>
			<div className="mt-3">
				<ul className="list-none p-0 m-0">
					<NavigationEntry icon={ENUM_ICONS.DASHBOARD} label='Dashboard' route={ENUM_ROUTES.FBIB_DASHBOARD} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} badge={badgeReminders} />
					<NavigationEntry icon={ENUM_ICONS.EAACASE} label='Fälle' route={ENUM_ROUTES.FBIB_CASES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry icon={ENUM_ICONS.COMPANY} label='Unternehmen' route={ENUM_ROUTES.FBIB_COMPANIES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.TRAININGCENTERS} icon={ENUM_AWESOMEICON.TRAININGCENTERS} label='Ausbildungsstätten' route={ENUM_ROUTES.FBIB_TRAININGCENTERS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.PROVIDERACTIVITIES} icon={ENUM_ICONS.PROVIDERACTIVITIES} label='Trägeraktivitäten' route={ENUM_ROUTES.FBIB_PROVIDERACTIVITIES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' ? '' : <NavigationEntry icon={ENUM_ICONS.ASSOCIATEDCONTACTS} awesomeIcon={ENUM_AWESOMEICON.ASSOCIATEDCONTACTS} label='Netzwerkkontakte' route={ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />}
					<NavigationEntry icon={ENUM_ICONS.JOBPROFILE} label='Stellenprofile' route={ENUM_ROUTES.FBIB_JOBPROFILES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry awesomeIcon={ENUM_AWESOMEICON.TRAININGCOURSES} icon={ENUM_AWESOMEICON.TRAININGCOURSES} label='Ausbildungsgänge' route={ENUM_ROUTES.FBIB_TRAININGCOURSES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry icon={ENUM_ICONS.STATISTICS} label='Statistik' route={ENUM_ROUTES.FBIB_STATISTICS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
						// reset the state of your app
					}}>
						{renderMenulink()}
					</ErrorBoundary>
				</ul>
			</div>


			<div className="mt-auto">
				<hr className="mx-3 border-top-1 border-none surface-border" />
				<div className="hidden flex-row mt-3 justify-content-center">
					<Button onClick={() => { onSidebarClick(ENUM_ROUTES.USERPROFILE, ENUM_ICONS.USER, 'Nutzer*innenprofil') }} icon={ENUM_ICONS.USER} className="p-button-rounded p-button-outlined color-lessblack" />
				</div>
				<div className="p-ripple my-3 gap-2 flex flex-row align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors" onClick={onLogout}>
					<i className="flex fa fa-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"></i>
					<span className={(showSidebarLabel ? 'flex inline' : 'hidden') + " font-medium"}>Abmelden</span>
					<Ripple />
				</div>
			</div>

		</div>
	)

}
