import { BreadcrumbsProvider } from './Persistence/Breadcrumbs';
import { OrganizationsProvider } from './Persistence/OrganizationsContext';
import { DataViewFilterProvider } from './Persistence/DataViewFilterContext';
import { CompaniesProvider } from './Persistence/CompaniesContext';
import { StrgPressedProvider } from './Persistence/StrgPressed';
import { EAACasesProvider } from './Persistence/EAACasesContext';
import { ProviderActivitiesProvider } from './Persistence/ProviderActivitiesContext';
import { UserProvider } from './Persistence/UserContext';
import { PostcodesProvider } from './Persistence/PostcodesContext';
import { RegionTagsProvider } from './Persistence/RegionTagsContext';
import { TaskareaTagsProvider } from './Persistence/TaskareaTagsContext';
import { ErrorProvider } from './Persistence/ErrorContext';
import { RemindersProvider } from './Persistence/RemindersContext';
import { RequestDistributionsProvider } from './Persistence/RequestDistributionsContext';
import { CaseRequestTypesProvider } from './Persistence/CaseRequestTypesContext';
import { AssociatedContactsProvider } from './Persistence/AssociatedContactsContext';
import { CaseEventsProvider } from './Persistence/CaseEventsContext';
import { NavigationPage } from './Navigation/NavigationPage';
import { useConfigurationsComplete, useConfigurationsList, useFBiBMode } from './Persistence/ConfigurationsContext';
import { useEffect } from 'react';
import { AssociatedOrganisationsProvider } from './Persistence/AssociatedOrganisationsContext';

export const ApplicationInit = () => {
	const configurationCompleted = useConfigurationsComplete();
	const configurationList = useConfigurationsList()

	useEffect(() => {
		document.title = 'EAA Management' + (configurationList && configurationList.title ? ' - ' + configurationList.title : '');
	}, [configurationCompleted])

	const renderPage = () => {
		return !configurationCompleted ?
			<div className='flex h-full w-full '>
				<div className='flex flex-column w-full h-full justify-content-center '>
					<div className='flex justify-content-center text-4xl'>Das EAA DOQ wird für Sie gestartet</div>
					<div className='flex w-full justify-content-center  '>
						<img src="../icons/logo_eaa-tool.png" className='flex w-5' />
					</div>
				</div>
			</div> :
			<CaseEventsProvider >
				<AssociatedContactsProvider >
					<AssociatedOrganisationsProvider>
						<RequestDistributionsProvider>
							<CompaniesProvider>
								<ProviderActivitiesProvider>
									<EAACasesProvider>
										<UserProvider>
											<OrganizationsProvider>
												<PostcodesProvider>
													<RegionTagsProvider>
														<CaseRequestTypesProvider>
															<TaskareaTagsProvider>
																<DataViewFilterProvider>
																	<RemindersProvider>
																		<BreadcrumbsProvider>
																			<StrgPressedProvider>
																				<ErrorProvider>
																					<NavigationPage />
																				</ErrorProvider>
																			</StrgPressedProvider>
																		</BreadcrumbsProvider>
																	</RemindersProvider>
																</DataViewFilterProvider>
															</TaskareaTagsProvider>
														</CaseRequestTypesProvider>
													</RegionTagsProvider>
												</PostcodesProvider>
											</OrganizationsProvider>
										</UserProvider>
									</EAACasesProvider>
								</ProviderActivitiesProvider>
							</CompaniesProvider>
						</RequestDistributionsProvider>
					</AssociatedOrganisationsProvider>
				</AssociatedContactsProvider>
			</CaseEventsProvider>
	}

	return renderPage()
}