import { Button } from 'primereact/button';
import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { CompanyForm, emptyCompany, validateCompany } from './CompanyForm';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { openCompany, patchCompany } from './CompanyManager';
import { copyObject } from '../snippets';
import { useConfigurationsList, useDataviewsConfig, useFBiBMode } from '../Persistence/ConfigurationsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useLocation } from 'react-router-dom';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.COMPANIES;
export const CompaniesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const fbib = useFBiBMode();
	const configurationsList = useConfigurationsList();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const location = useLocation()

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ filterAlias: 'col_03', field: "responsible", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ filterAlias: 'col_04', field: "employees", style: { width: '110px' }, align: 'right' },
		{ filterAlias: 'col_05', field: "employeesSbM", style: { width: '60px' }, align: 'right' },
		{ filterAlias: 'col_06', field: "quote", header: "Quote", style: { width: '70px' }, align: 'right', body: (rowData) => { return (rowData.quote / 100).toLocaleString("de-DE", { style: "percent", minimumFractionDigits: 1 }) }, },
		{ filterAlias: 'col_07', field: "createdAt", header: "Anlagedatum", style: { width: '180px' }, body: 'renderDate' },
		{ filterAlias: 'col_08', field: "region.name", style: { width: '200px' }, body: null },
		{ filterAlias: 'col_09', field: "active", style: { width: '150px' }, body: 'renderTag' }
	]

	return (
		<div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				displayDialog={displayCompanyDialog}
				formik={formikCompany}
				labelButtonOk='Anlegen'
				header='Unternehmen anlegen'
				message='Legen Sie ein neues Unternehmen an'
				patchForm={async () => {
					const company = { ...formikCompany.values, origin: (fbib ? 'FBIB' : 'EAA') }
					await patchCompany(company, currentUser).then((company) => {
						addBreadcrumb(`${company.name}`, `${fbib ? ENUM_ROUTES.FBIB_COMPANIES : ENUM_ROUTES.COMPANIES}/` + company.id);
					})
				}}
				setDisplayDialog={setDisplayCompanyDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={(id) => { formikCompany.setTouched({}); openCompany(id, addBreadcrumb, fbib) }}
				/>}
			/>
			<TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-companies'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={fbib ? ENUM_ROUTES.FBIB_COMPANIES : ENUM_ROUTES.COMPANIES}
				buttons={[<Button key={'button-new-company'} disabled={isReader} onClick={() => { formikCompany.setValues(copyObject(emptyCompany)); setDisplayCompanyDialog(true) }} className='flex  text-white' label='Unternehmen anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>
		</div>
	)

}