import React, { useContext, useEffect, useState } from "react";
import { setupSocketErrorHandling } from "../feathers";
import logger, { logContext } from "../Utils/logger";
import { useCurrentUserUpdate } from "./CurrentUserContext";

const CLASSNAME = 'SocketErrorContext';
const SocketErrorContext = React.createContext();
const SocketErrorUpdateContext = React.createContext();
const SocketReconnectContext = React.createContext();

export function useSocketError() {
	return useContext(SocketErrorContext);
}
export function useSocketErrorUpdate() {
	return useContext(SocketErrorUpdateContext);
}
export function useSocketReconnect() {
	return useContext(SocketReconnectContext);
}
export function SocketErrorProvider({ children }) {
	const [socketError, setSocketError] = useState(null);
	const [socketReconnectFunction, setSocketReconnectFunction] = useState(null);
	const setCurrentUser = useCurrentUserUpdate();
	const [counter, setCounter] = useState(0)

	function updateSocketError(_socketError) {
		//logger.info(`############# updateSocketError ############### ${JSON.stringify(_socketError)}`);
		setSocketError(_socketError);
	}
	function updateSocketReconnectFunction(_socketReconnectFunction) {
		setSocketReconnectFunction(() => _socketReconnectFunction);
	}

	useEffect(() => {
		logger.info(`############# updateSocketReconnectFunction ###############`, socketReconnectFunction);
	}, [socketReconnectFunction])


	useEffect(() => {
		const errorHandler = (error) => {
			logContext(CLASSNAME, 'info', "errorHandler Application:", error)
			if (error !== "ping timeout" && error !== "transport close") {
				setCounter(counter + 1);
				if (counter > 0) {
					setSocketError("Achtung!!! Die Verbindung zum Server konnte nicht hergestellt werden!")
				}
			}
		};
		const successHandler = () => {
			logContext(CLASSNAME, 'info', "successHandler Application reload after socketError:", socketError)
			if (counter !== 0) {
				setSocketError(null);
				setCounter(0);
				if (socketReconnectFunction) {
					socketReconnectFunction();
				}
			}
		};

		setupSocketErrorHandling(errorHandler, successHandler)
	}, [socketError, setCurrentUser, counter]);

	return (
		<SocketErrorContext.Provider value={socketError}>
			<SocketErrorUpdateContext.Provider value={updateSocketError}>
				<SocketReconnectContext.Provider value={updateSocketReconnectFunction}>
					{children}
				</SocketReconnectContext.Provider>
			</SocketErrorUpdateContext.Provider>
		</SocketErrorContext.Provider>
	)
}