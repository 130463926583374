import { format, differenceInSeconds, differenceInMilliseconds } from "date-fns";
import logger, { logContext } from "./Utils/logger";
import { FindDataError } from "./errorClasses";
import client from "./feathers";

const dateFormat = 'yyy-MM-dd';
//export const formatDate = (date) => { return format(date || new Date(), dateFormat) }
export const formatDateTime = (date) => { return format(date || new Date(), dateFormat + ' HH:mm') }

export const copyObject = (object) => {
	return JSON.parse(JSON.stringify(object))
}

export const printStopwatchTime = (title, start, millis) => {
	const difference = (millis ? differenceInMilliseconds(new Date(), start) + ' milliseconds' : differenceInSeconds(new Date(), start) + ' seconds')
	logger.info(`stopwatch: '${title}' takes ${difference}`)
}

export const getSortedList = (list, fieldname, descending) => {

	const listSorted = list.sort((a, b) => {
		let x = a[fieldname];
		let y = b[fieldname];
		if (typeof x === 'string' || x instanceof String) { x = x.toLowerCase() }
		if (typeof y === 'string' || y instanceof String) { y = y.toLowerCase() }
		if (x < y) { return !descending ? -1 : 1 }
		if (x > y) { return !descending ? 1 : -1 }
		return 0;
	})
	return listSorted
}

export const fetchDataWithPagination = async (servicename, setList, query) => {
	logContext('fetchDataWithPagination', 'info', '########### ' + servicename + ' getData');
	const service = client.service(servicename);
	let $skip = 0;
	let _list = [];
	let count = 0;
	do {
		count = await service.find({ query: query ? { ...query, $skip, $limit: 50 } : { $skip, $limit: 50 } }).then((result) => {
			_list = _list.concat(result);
			$skip = _list.length
			return result.length;
		}).catch((error) => {
			console.error("Error fetchDataWithPagination service:" + servicename, error)
			throw new FindDataError("Error fetchDataWithPagination service:" + servicename, error, servicename)
		});
	} while (count > 0);
	logContext('fetchDataWithPagination', 'info', `########### ${servicename} fetchDataWithPagination found:${_list.length}`);
	setList(_list);
	return
}

export const getUniqueList = (list, fieldname) => {
	return list.filter((item, index, self) =>
		index === self.findIndex((t) => t[fieldname] === item[fieldname])
	)
}
